import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Button, TextField, MenuItem, CircularProgress } from '@mui/material';

import Select from 'react-select';

import AdditionalQuestionContext from '../Store/AdditionalQuestionContext';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import hourglassImg from "../Assets/Images/hourglass.gif";

import '../App.scss';

const jobTitleValues = [
  // {value: "Administrative Pastor", label: "Administrative Pastor"},
  // {value: "Lead Pastor", label: "Lead Pastor"},
  // {value: "Campus Pastor", label: "Campus Pastor"},
  // {value: "Children's Pastor", label: "Children's Pastor"},
  // {value: "College Pastor", label: "College Pastor"},
  // {value: "Community Pastor", label: "Community Pastor"},
  // {value: "Connections Pastor", label: "Connections Pastor"},
  // {value: "Creative Arts Pastor", label: "Creative Arts Pastor"},
  // {value: "Discipleship Pastor", label: "Discipleship Pastor"},
  // {value: "Executive Pastor", label: "Executive Pastor"},
  // {value: "Family Pastor", label: "Family Pastor"},
  // {value: "High School Pastor", label: "High School Pastor"},
  // {value: "Leadership Development Pastor", label: "Leadership Development Pastor"},
  // {value: "Middle School Pastor", label: "Middle School Pastor"},
  // {value: "Missions Pastor", label: "Missions Pastor"},
  // {value: "Outreach Pastor", label: "Outreach Pastor"},
  // {value: "Personal Assistant to Lead Pastor", label: "Personal Assistant to Lead Pastor"},
  // {value: "Senior Pastor", label: "Senior Pastor"},
  // {value: "Small Group Pastor", label: "Small Group Pastor"},
  // {value: "Stewardship Pastor", label: "Stewardship Pastor"},
  // {value: "Student Pastor", label: "Student Pastor"},
  // {value: "Teacher Pastor", label: "Teacher Pastor"},
  // {value: "Worship Pastor", label: "Worship Pastor"},
  // {value: "Administrative Assistant", label: "Administrative Assistant"},
  // {value: "AV Director", label: "AV Director"},
  // {value: "Bookkeeper", label: "Bookkeeper"},
  // {value: "Chief Financial Officer", label: "Chief Financial Officer"},
  // {value: "Children's Ministry Coordinator", label: "Children's Ministry Coordinator"},
  // {value: "Communications Coordinator", label: "Communications Coordinator"},
  // {value: "Communications Director", label: "Communications Director"},
  // {value: "Communications Project Manager", label: "Communications Project Manager"},
  // {value: "Content Writer", label: "Content Writer"},
  // {value: "Digital Media Analyst", label: "Digital Media Analyst"},
  // {value: "Events Coordinator", label: "Events Coordinator"},
  // {value: "Facility Manager", label: "Facility Manager"},
  // {value: "Graphic Designer", label: "Graphic Designer"},
  // {value: "Groups Director", label: "Groups Director"},
  // {value: "Guest Services Director", label: "Guest Services Director"},
  // {value: "Human Resources Coordinator", label: "Human Resources Coordinator"},
  // {value: "IT Director", label: "IT Director"},
  // {value: "Mission and Outreach Coordinator", label: "Mission and Outreach Coordinator"},
  // {value: "Office Manager", label: "Office Manager"},
  // {value: "Online Community Manager", label: "Online Community Manager"},
  // {value: "Pastoral Care Minister", label: "Pastoral Care Minister"},
  // {value: "Preschool Director", label: "Preschool Director"},
  // {value: "Production Coordinator", label: "Production Coordinator"},
  // {value: "Social Media Manager", label: "Social Media Manager"},
  // {value: "Video Producer", label: "Video Producer"},
  // {value: "Volunteer Coordinator", label: "Volunteer Coordinator"},
  // {value: "Web Developer", label: "Web Developer"},
  // {value: "Worship Leader", label: "Worship Leader"},
  { value: "Business Development",     label: "Business Development"     },
  { value: "Consultant",               label: "Consultant"               },
  { value: "Customer Service/Support", label: "Customer Service/Support" },
  { value: "Finance",                  label: "Finance"                  },
  { value: "Marketing Associate",      label: "Marketing Associate"      },
  { value: "Marketing Leader/Manager", label: "Marketing Leader/Manager" },
  { value: "Operations",               label: "Operations"               },
  { value: "Owner/Founder",            label: "Owner/Founder"            },
  { value: "Sales Leader/Manager",     label: "Sales Leader/Manager"     },
  { value: "Sales Associate",          label: "Sales Associate"          },
  { value: "Other",                    label: "Other"                    },
];

const Details = (props) => {

  const addCtx = useContext(AdditionalQuestionContext);

  let navigate                          = useNavigate();
  const { id }                          = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving]         = useState(false);
  const [isLoading, setIsLoading]       = useState(false);
  const [isFormValid, setIsFormValid]   = useState(false);
  const [isMember, setIsMember]         = useState(true);

  const [firstName, setFirstName]       = useState("");
  const [lastName, setLastName]         = useState("");
  const [email, setEmail]               = useState("");
  const [jobTitle, setJobTitle]         = useState("");
  const [jobTitleMember, setJobTitleMember]   = useState("");
  const [churchName, setChurchName]     = useState("");
  const [zipCode, setZipCode]           = useState("");
  const [selectedValue, setSelectedValue]     = useState();

  const [firstNameError, setFirstNameError]   = useState(false);
  const [lastNameError, setLastNameError]     = useState(false);
  const [emailError, setEmailError]           = useState(false);
  const [jobTitleError, setJobTitleError]     = useState(false);
  const [churchNameError, setChurchNameError] = useState(false);
  const [zipCodeError, setZipCodeError]       = useState(false);

  useEffect(() => {
    // document.body.style.backgroundColor = "#152036";
    console.log("Do we run again");
    if(searchParams.get("member") != null) {
      getUserDetails();
    } else {
      setIsMember(false);
    }
    // go to last additional question when you click back on Details page 
    // localStorage.setItem("backFromDetailsPage", true);
  }, []);

  useEffect(() => {
    if(jobTitle.length > 0) document.getElementsByClassName("react-select__control")[0].style.border = "1px solid #343333";
    else if(!jobTitle) document.getElementsByClassName("react-select__control")[0].style.border = "none";
    // document.getElementsByClassName("react-select__control--is-focused")[0].style.border = "none";
  }, [jobTitle]);

  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]{2,}$/;

  useEffect(() => {
    if (firstName  !== addCtx.formDetails.firstName)  addCtx.addFormDetails((prev) => ({ ...prev, firstName  }));
    if (lastName   !== addCtx.formDetails.lastName)   addCtx.addFormDetails((prev) => ({ ...prev, lastName   }));
    if (email      !== addCtx.formDetails.email)      addCtx.addFormDetails((prev) => ({ ...prev, email      }));
    if (jobTitle   !== addCtx.formDetails.jobTitle)   addCtx.addFormDetails((prev) => ({ ...prev, jobTitle   }));
    if (churchName !== addCtx.formDetails.churchName) addCtx.addFormDetails((prev) => ({ ...prev, churchName }));
    if (zipCode    !== addCtx.formDetails.zipCode)    addCtx.addFormDetails((prev) => ({ ...prev, zipCode    }));
  }, [firstName, lastName, email, jobTitle, churchName, zipCode]);

  useEffect(() => {
    let formDetails = addCtx.formDetails;

    if (formDetails?.firstName?.length)  setFirstName(formDetails.firstName);
    if (formDetails?.lastName?.length)   setLastName(formDetails.lastName);
    if (formDetails?.email?.length)      setEmail(formDetails.email);
    if (formDetails?.churchName?.length) setChurchName(formDetails.churchName);
    if (formDetails?.zipCode?.length)    setZipCode(formDetails.zipCode);

    if (formDetails?.jobTitle?.length) {
      let selected = jobTitleValues?.find(job => job.value === formDetails?.jobTitle);
      console.log("typeof selected", typeof selected, selected);
      setSelectedValue(selected);
      setJobTitle(selected.value);
    }
  }, [addCtx.formDetails])

  console.log("addCtx.formDetails", addCtx.formDetails);
  console.log("jobTitle", jobTitle)
  console.log("selectedValue", selectedValue)
  // console.log("typeof jobTitle", typeof jobTitle, jobTitle);

  const getUserDetails = () => {
    setIsLoading(true);
  
    fetch(process.env.REACT_APP_API_URI + '/v1/user/' + searchParams.get("member"), {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        setIsMember(true);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        // setJobTitle("none");
        if(!addCtx.formDetails.jobTitle) setJobTitle("");
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });
  }

  const updateFirstName = (event) => {
    setFirstName(event.target.value);
    checkFormValid();
  }

  const updateLastName = (event) => {
    setLastName(event.target.value);
    checkFormValid();
  }

  const updateEmail = (event) => {
    setEmail(event.target.value);
    checkFormValid();
  }

  // const updateJobTitle = (event) => {
  const updateJobTitle = (selectedOption) => {
    // console.log(event.target.value);
    console.log(selectedOption.value);
    setSelectedValue(selectedOption);
    setJobTitle(selectedOption.value);
    checkFormValid();
  }
  
  const updateChurchName = (event) => {
    setChurchName(event.target.value);
    checkFormValid();
  }

  const updateZipCode = (event) => {
    setZipCode(event.target.value);
    checkFormValid();
  }
  
  const checkFormValid = () => {
    if(isMember) {
      console.log(firstName);
      console.log(jobTitle);
      console.log(jobTitleMember);
      if(firstName && lastName) {
        console.log("We get here");
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    } else {
      if(firstName && lastName && email && emailRegex.test(email) && jobTitle && churchName && zipCode.length > 3 && zipCode.length < 13) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }
  }

  const showErrors = () => {
    if(!firstName) {
      setFirstNameError(true);
      setTimeout(() => { console.log(firstNameError); }, 2000);
    }
    if(!lastName) {
      setLastNameError(true);
    }
    if(!emailRegex.test(email)) {
      setEmailError(true);
    }
    if(!jobTitle) {
      setJobTitleError(true);
    }
    if(!churchName) {
      setChurchNameError(true);
    }
    if(zipCode.length < 4) {
      setZipCodeError(true);
    }
  }

  // let goToAdditionalInfoPage = false;
  // if(jobTitle === "Executive Pastor" || jobTitle === "Senior Pastor" || jobTitle === "Lead Pastor") {
  //   goToAdditionalInfoPage = true;
  // }


  const submitToApi = () => {
    
    let apiCall         = '/v1/assessment/' + id + '/leader';
    if(isMember) {
      apiCall           = '/v1/assessment/' + id + '/member/' + searchParams.get("member");
    }
    
    setIsLoading(true);

    let jsonBody        = JSON.stringify({
      details: {
        "firstName": firstName,
        "lastName": lastName,
        "email": email?.toString().toLowerCase(),
        "jobTitle": jobTitle,
        "churchName": churchName,
        "zipCode": zipCode
      },
      formVersion: 2
    });

    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody,
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        setIsLoading(false);
        // if(goToAdditionalInfoPage) {
        //   // navigate("/pre-additional-info/" + id + "?" + searchParams, { replace: true });
        //   navigate("/assessment-additional/" + id + "?" + searchParams, { replace: true });
        // } else {
        //   localStorage.removeItem("assessmentAnswers");
        //   localStorage.removeItem("additionalAnswers");
        //   navigate("/results/" + id, { replace: true });
        // }
        localStorage.removeItem("assessmentAnswers");
        localStorage.removeItem("additionalAnswers");
        localStorage.removeItem("sections");
        navigate("/results/" + id, { replace: true });
      } else {
        // setOpenSnackbar(true);
        setIsLoading(false);
      }
    }).catch((err) => {
      setIsLoading(false);
      console.error(err);
    });

  }

  const handleSubmit = () => {

    setIsSaving(true);
    console.log("Runnign");


    if(isMember && firstName && lastName && jobTitle) {
      submitToApi();
    } else if(!isMember && firstName && lastName && emailRegex.test(email) && jobTitle && churchName && zipCode.length > 3 && zipCode.length < 13) {
      submitToApi();
    } else {
      showErrors();
      setIsSaving(false);
    }

  }

  const showButton = () => {
    if(isSaving) {
      return <div className="button-progress"><CircularProgress style={{ color: '#FFFFFF' }} size={23} /></div>
    }
    return "SHOW MY RESULTS";
  }

  const showHintText = (field) => {
    if(field == "firstName") {
      if(firstNameError) {
        return <div className="error-text">Please confirm your first name.</div>
      }
    }
    if(field == "lastName") {
      if(lastNameError) {
        return <div className="error-text">Please confirm your last name.</div>
      }
    }
    if(field == "email") {
      if(emailError) {
        return <div className="error-text">Please enter a valid email address.</div>
      }
    }
    if(field == "jobTitle") {
      if(jobTitleError) {
        return <div className="error-text">Please select an option.</div>
      }
    }
    if(field == "churchName") {
      if(churchNameError) {
        return <div className="error-text">Please confirm your organization name.</div>
      }
    }
    if(field == "zipCode") {
      if(zipCodeError) {
        return <div className="error-text">Please confirm your ZIP code.</div>
      }
    }
  }

  const removeHintText = (field, e) => {

    // change active input field title color 
    if(e.target.closest(".form-detail")) {
      document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#202945");
      e.target.closest(".form-detail").querySelector(".input-title").style.color = "#202945";
    }

    if(field == "firstName") {
      setFirstNameError(false);
    }
    if(field == "lastName") {
      setLastNameError(false);
    }
    if(field == "email") {
      setEmailError(false);
    }
    if(field == "jobTitle") {
      setJobTitleError(false);
    }
    if(field == "churchName") {
      setChurchNameError(false);
    }
    if(field == "zipCode") {
      setZipCodeError(false);
    }
  }

  const prevQuestion = () => {
    // if (hasAdditional) {
    //   navigate("/assessment-additional/" + id, { replace: true });
    // } else {
    //   navigate("/assessment/" + id, { replace: true });
    // }

    // if(isMember) {
    //   navigate('/assessment-additional/' + id + '?member=' + searchParams.get("member"), { replace: true });
    // } else {
    //   navigate('/assessment-additional/' + id, { replace: true });
    // }
    // navigate("/assessment/" + id + '/review', { replace: true });
    navigate('/assessment-additional/' + id + "?" + searchParams, { replace: true });
  }

  let nextBtnDisabled = true;
  if(isMember) {
    if(firstName && lastName && jobTitle) nextBtnDisabled = false;
  } else {
    if(firstName && lastName && emailRegex.test(email) && jobTitle && churchName && zipCode.length > 3 && zipCode.length < 13) {
      nextBtnDisabled = false;
    }
  }

  let formSubmitDisabled = isMember ? (!firstName || !lastName || !emailRegex.test(email) || !jobTitle) : (!firstName || !lastName || !emailRegex.test(email) || !jobTitle || !churchName || !(zipCode.length > 3 && zipCode.length < 13));

  if (isSaving) nextBtnDisabled = true;
  console.log("isMember", isMember);

  if (isLoading) {
		return <div className="container details loading"><CircularProgress style={{ color: "#FAD41F" }} size={60} /></div>
	}
  
  return (
    <div className="container details">
      
      <div className="details-container">
        
        <div className="header-text-img" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {/* <img src={hourglassImg} alt="hourglass" height="38px" width="auto" style={{ padding: "32px 0 0" }} /> */}
          <h1 style={{ margin: "0", padding: "32px 15px 0 5px" }}>Your report is in progress.</h1>
        </div>
        <div style={{ height: isMember ? '45px' : '10px' }}></div>
        <p>Please confirm your details so that we can email you a copy.</p>

        <div className="form">

          <div className="form-detail">
            <p className="input-title">First Name<span className="mark-required">*</span></p>
            <TextField required hiddenLabel placeholder="Simon" value={firstName} onFocus={(e) => removeHintText("firstName", e)} onChange={(e) => {updateFirstName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" style={{ border: firstName ? "1px solid #343333" : "none" }} />
            {showHintText("firstName")}
          </div>

          <div className="form-detail">
            <p className="input-title">Last Name<span className="mark-required">*</span></p>
            <TextField required hiddenLabel placeholder="Peter" value={lastName} onFocus={(e) => removeHintText("lastName", e)}  onChange={(e) => {updateLastName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" style={{ border: lastName ? "1px solid #343333" : "none" }} />
            {showHintText("lastName")}
          </div>

          <div className="form-detail">
            <p className="input-title">Email<span className="mark-required">*</span></p>
            <TextField type="email" required hiddenLabel placeholder="speter@rock.church" disabled={isMember} value={email} onFocus={(e) => removeHintText("email", e)} onChange={(e) => {updateEmail(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" style={{ border: emailRegex.test(email) ? "1px solid #343333" : "none" }} />
            {showHintText("email")}
          </div>

          <div className="form-detail">
            <p className="input-title">Job Title<span className="mark-required">*</span></p>
            {/* <TextField required select hiddenLabel value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
              <MenuItem key={0} value="none" disabled>Start typing</MenuItem>
              {jobTitleValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField> */}
            
            {/* Added react-select instead of MUI TextField */}
            <Select classNamePrefix="react-select" className="form-details-text react-select" 
              options={jobTitleValues} 
              onFocus={(e) => removeHintText("jobTitle", e)} 
              onChange={updateJobTitle} 
              value={selectedValue}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  // primary25: '#678F9D44',
                  // primary: '#678F9D',
                  primary25: '#F0DC7C',
                  primary: '#0F4359',
                  neutral0: '#F4F4F4',
                  neutral50: '#AAAAAA',
                },
              })}
              placeholder={"Please select/start typing"} />
            {showHintText("jobTitle")}
          </div>

          {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">Organization Name<span className="mark-required">*</span></p>
                <TextField required hiddenLabel placeholder="Rock Church" value={churchName} onFocus={(e) => removeHintText("churchName", e)} onChange={(e) => {updateChurchName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" style={{ border: churchName ? "1px solid #343333" : "none" }} />
                {showHintText("churchName")}
              </div>
            ) : null
          }
          
          {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">ZIP Code<span className="mark-required">*</span></p>
                <TextField required type="text" hiddenLabel placeholder="11618" value={zipCode} 
                  onFocus={(e) => removeHintText("zipCode", e)} 
                  onChange={(e) => {updateZipCode(e)}} 
                  onInput = {(e) => {
                    return e.target.value = e.target.value?.toString().slice(0,12)
                  }}
                  fullWidth variant="standard" size="normal" 
                  InputProps={{ disableUnderline: true }} className="form-details-text" 
                  style={{ border: (zipCode.length > 3 && zipCode.length < 13) ? "1px solid #343333" : "none" }}
                />
                {showHintText("zipCode")}
              </div>
            ) : null
          }
        </div>

        <div className="buttons" 
          // style={{ margin: isMember ? "45px auto 0" : "0 auto", display: "flex", justifyContent: "space-between", maxWidth: "90%" }}
        >
          <div className="back-btn">
            <Button style={{ color: "#333333", padding: "10px 15px" }} onClick={prevQuestion}>
              <img src={prevArrow} />&nbsp;&nbsp;PREV
            </Button>
          </div>
          
          <div className="show-results">
            {/* <Button onClick={handleSubmit} disabled={isSaving}> */}
            <Button onClick={handleSubmit} disabled={formSubmitDisabled}>
              {showButton()}
            </Button>
          </div>

          {/* <div className="next-btn"> */}
            {/* <Button style={{ color: "#333333", padding: "10px 15px" }} onClick={handleSubmit} disabled={isSaving}> */}
              {/* {showButton()} */}
              {/* NEXT&nbsp;&nbsp;<img src={nextArrow} /> */}
            {/* </Button> */}
          {/* </div> */}
        </div>

      </div>
    </div>
  );
  
}
export default Details;